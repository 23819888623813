import React from 'react';
import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import Div from "@vkontakte/vkui/dist/components/Div/Div";
import Button from "@vkontakte/vkui/dist/components/Button/Button";
import FormLayout from "@vkontakte/vkui/dist/components/FormLayout/FormLayout";
import Input from "@vkontakte/vkui/dist/components/Input/Input";
import FormLayoutGroup from "@vkontakte/vkui/dist/components/FormLayoutGroup/FormLayoutGroup";
import Textarea from "@vkontakte/vkui/dist/components/Textarea/Textarea";
import Icon24Document from "@vkontakte/icons/dist/24/document";
import File from "@vkontakte/vkui/dist/components/File/File";
import Switch from "@vkontakte/vkui/dist/components/Switch/Switch";
import SimpleCell from "@vkontakte/vkui/dist/components/SimpleCell/SimpleCell";
import Text from "@vkontakte/vkui/dist/components/Typography/Text/Text";
import {Group, Link, Select} from "@vkontakte/vkui";
import Icon24BrowserBack from "@vkontakte/icons/dist/24/browser_back";
import DatePicker from "react-datepicker";
import API from "../utils/API";
import FormError from "./FormError";
import moment from 'moment'
import DayPicker, {DateUtils} from "react-day-picker";
import ScreenSpinner from "@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {Icon24GearOutline, Icon24Linked, Icon24List} from "@vkontakte/icons";
import AppBar from "@material-ui/core/AppBar";
import TabPanel from "./TabPanel";
import coverImage from "../img/blank.png";

export default class CreateContest extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            amo_data: this.props.navigator.params.amo_data,
            amo_user_id: '',
            amo_pipeline_id: '',
            amo_status_id: '',
            file: '',
            imagePreviewUrl: coverImage,
            title: '',
            description: '',
            thx_text: '',
            tags: '',
            utm_source: '',
            utm_medium: '',
            utm_campaign: '',
            utm_content: '',
            utm_term: '',
            first_tags: '',
            date_start: new Date(),
            date_end: new Date(),
            welcome_message: '',
            active: false,
            disable_verify: false,
            disable_mlm: false,
            isError: false,
            days: this.getInitialState(),
            isLoader: false,
            verify_btn_name: "Пройти верификацию",
            tab: 1,
            custom_btn: false,
            custom_btn_name: '',
            custom_btn_link: ''
        }


        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
    }

    getInitialState() {
        return {
            from: undefined,
            to: undefined,
        };
    }

    handleAmoUser = (e) => {
        console.log(e);
        this.props.dispatch({
            type: 'HANDLE_SET_AMO_USER_ID',
            data: e.target.value
        });
        this.setState({amo_user_id: e.target.value});
    }

    handleAmoPipeline = (e) => {
        console.log(e);
        this.props.dispatch({
            type: 'HANDLE_SET_AMO_PIPELINE_ID',
            data: e.target.value
        });
        this.setState({amo_pipeline_id: e.target.value});
    }
    handleAmoStatus = (e) => {
        console.log(e);
        this.props.dispatch({
            type: 'HANDLE_SET_AMO_STATUS_ID',
            data: e.target.value
        });
        this.setState({amo_status_id: e.target.value});
    }
    handleSubmit = async (e) => {
        this.setState({isLoader: true})
        e.preventDefault();

        await API.post('/contest/create', {
            amo_user_id: this.state.amo_user_id,
            amo_pipeline_id: this.state.amo_pipeline_id,
            amo_status_id: this.state.amo_status_id,
            name: this.state.title,
            description: this.state.description,
            thx_text: this.state.thx_text,
            tags: this.state.tags,
            first_tags: this.state.first_tags,
            utm_source: this.state.utm_source,
            utm_medium: this.state.utm_medium,
            utm_campaign: this.state.utm_campaign,
            utm_content: this.state.utm_content,
            utm_term: this.state.utm_term,
            date_start: moment(this.state.date_start).format("YYYY-MM-DD 00:00:00"),
            date_end: moment(this.state.date_end).format("YYYY-MM-DD 23:59:59"),
            disable_verify: this.state.disable_verify,
            disable_mlm: this.state.disable_mlm,
            active: this.state.active,
            welcome_message: this.state.welcome_message,
            cover: this.state.imagePreviewUrl,
            verify_btn_name: this.state.verify_btn_name,
            bot_name: this.state.bot_name,
            custom_btn: this.state.custom_btn,
            custom_btn_name: this.state.custom_btn_name,
            custom_btn_link: this.state.custom_btn_link
        },{
            headers:{
                Authorization: `Bearer ${this.props.store.userReducer.userInfo.token}`
            }
        }).then(res => {
            if (res.data.success) {
                this.setState({isLoader: false})
                this.props.navigator.go("contests");
            } else {
                this.setState({
                    isError: true,
                    errorText: res.data.error
                });
            }
        }).catch(e => {
            this.setState({isError: true});
        });
    }

    _handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        }

        reader.readAsDataURL(file)

    }

    handleDisableVerify = () => {
        this.setState({disable_verify: !this.state.disable_verify});
    }

    handleDisableMlm = () => {
        this.setState({disable_mlm: !this.state.disable_mlm});
    }

    handleActive = () => {
        this.setState({active: !this.state.active});
    }

    setDate = (dates) => {
        this.setState({
            date_start: dates[0],
            date_end: dates[1]
        })
    }

    handleDayClick(day) {
        console.log('day', day);
        //this.setState({days: this.getInitialState()})
        const range = DateUtils.addDayToRange(day, {from: this.state.days.from, to: this.state.days.to});
        console.log('range', range);

        this.setState(range);
    }

    handleResetClick() {
        this.setState(
          {days: this.getInitialState()}
        );
    }

    handleTab = (event, value) => {
        this.setState({tab: value});
    }

    render() {
        const {from, to} = this.state.days;
        const modifiers = {start: from, end: to};

        return <Panel id={"create_contest"}>
            <AppBar position="static" color="default">
                <Tabs
                  value={this.state.tab} onChange={this.handleTab}
                  aria-label="simple tabs example"
                  variant="fullWidth"
                  indicatorColor="primary"
                >
                    <Tab onClick={() => this.props.navigator.go("contests")} icon={<Icon24BrowserBack/>} />
                    <Tab icon={<Icon24List/>} />
                    <Tab icon={<Icon24GearOutline/>} />
                </Tabs>
            </AppBar>
            <FormLayoutGroup>
                {this.state.isLoader ? <ScreenSpinner/> :
                  <FormLayout onSubmit={this.handleSubmit}>
                      {this.state.isError ? <FormError error={['Не удалось добавить. Что то пошло не так.']}/> : null}
                      <TabPanel index={1} value={this.state.tab}>
                          <SimpleCell>
                              <Div><Text weight="regular">Название</Text></Div>
                              <Input top="Название" type="text"
                                     onChange={(e) => this.setState({title: e.target.value})}
                              />
                          </SimpleCell>
                          <SimpleCell>
                              <Div><Text weight="regular">Описание</Text></Div>
                              <Textarea top="Описание"
                                        onChange={(e) => this.setState({description: e.target.value})}
                              />
                          </SimpleCell>
                          <SimpleCell>
                              {this.state.imagePreviewUrl !== '' ?
                                <Div><img src={this.state.imagePreviewUrl} alt={'Обложка конкурса'}
                                          style={{width: '100%'}}/></Div> : null}
                              <File top="Загрузите обложку (jpg)"
                                    before={<Icon24Document/>}
                                    controlSize="xl"
                                    onChange={(e) => this._handleImageChange(e)}
                              />
                          </SimpleCell>
                          <SimpleCell>
                              <Div><Text weight="regular">Текст кнопки верификции</Text></Div>
                              <Input top="Текст кнопки верификации"
                                     type="text"
                                     value={this.state.verify_btn_name}
                                     onChange={(e) => this.setState({verify_btn_name: e.target.value})}
                              />
                          </SimpleCell>
                          <SimpleCell>
                              <Div><Text weight="regular">Тэги</Text></Div>
                              <Input top="Тэги" type="text"
                                     onChange={(e) => this.setState({tags: e.target.value})}
                              />
                          </SimpleCell>
                          <SimpleCell>
                              <Div>
                                  <Text weight="regular">1st Теги</Text>
                              </Div>
                              <Input top="1st Теги" type="text" onChange={(e) => this.setState({first_tags: e.target.value})}/>
                          </SimpleCell>

                          <SimpleCell>
                              <Div>
                                  <Text weight="regular">utm_source</Text>
                              </Div>
                              <Input top="Attributio" type="text" onChange={(e) => this.setState({utm_source: e.target.value})}/>
                          </SimpleCell>
                          <SimpleCell>
                              <Div>
                                  <Text weight="regular">utm_medium</Text>
                              </Div>
                              <Input top="Attributio" type="text" onChange={(e) => this.setState({utm_medium: e.target.value})}/>
                          </SimpleCell>
                          <SimpleCell>
                              <Div>
                                  <Text weight="regular">utm_campaign</Text>
                              </Div>
                              <Input top="Attributio" type="text" onChange={(e) => this.setState({utm_campaign: e.target.value})}/>
                          </SimpleCell>
                          <SimpleCell>
                              <Div>
                                  <Text weight="regular">utm_content</Text>
                              </Div>
                              <Input top="Attributio" type="text" onChange={(e) => this.setState({utm_content: e.target.value})}/>
                          </SimpleCell>
                          <SimpleCell>
                              <Div>
                                  <Text weight="regular">utm_term</Text>
                              </Div>
                              <Input top="Attributio" type="text" onChange={(e) => this.setState({utm_term: e.target.value})}/>
                          </SimpleCell>

                          <SimpleCell>
                              <Div><Text weight="regular">Текст после отправки</Text></Div>
                              <Textarea top="Текст после отправки"
                                        onChange={(e) => this.setState({thx_text: e.target.value})}
                              />
                          </SimpleCell>
                          {/*<Div>*/}
                          {/*    Даты проведения конкурса*/}
                          {/*    <DayPicker*/}
                          {/*        className="Selectable"*/}
                          {/*        numberOfMonths={2}*/}
                          {/*        selectedDays={[from, { from, to }]}*/}
                          {/*        modifiers={modifiers}*/}
                          {/*        onDayClick={this.handleDayClick}*/}
                          {/*    />*/}
                          {/*</Div>*/}
                          <SimpleCell>
                              <Div><Text weight="regular">Приветственное сообщение в диалоге</Text></Div>
                              <Textarea top="Приветственное сообщение в диалоге"
                                        value={this.state.welcome_message}
                                        onChange={(e) => this.setState({welcome_message: e.target.value})}
                              />
                          </SimpleCell>
                      </TabPanel>
                      <TabPanel index={2} value={this.state.tab}>
                          <Group mode="plain">
                              <SimpleCell after={<Switch onChange={this.handleDisableVerify}/>}>
                                  <Text weight="regular">Отключить
                                      верификацию</Text>
                              </SimpleCell>
                              {this.state.disable_verify ? null:
                                <SimpleCell
                                  after={<Input
                                    type='text'
                                    value={this.state.bot_name}
                                    onChange={(e) => this.setState({bot_name: e.target.value})}
                                  />}>

                                    <Text weight="regular">Имя TG бота</Text>
                                </SimpleCell>
                              }
                          </Group>
                          <Group mode="plain">
                              <SimpleCell
                                after={<Switch checked={this.state.disable_mlm}
                                               onChange={this.handleDisableMlm}/>}><Text
                                weight="regular">Отключить mlm</Text>
                              </SimpleCell>
                          </Group>
                          <Group mode="plain">
                              <SimpleCell after={<Select type="text" onChange={this.handleAmoUser}>
                                  <option value="">выберите из списка</option>
                                  {
                                      this.state.amo_data.users.map((element) => {

                                          return <option value={element.id}>{element.name}</option>;
                                      })}
                              </Select>}><Text weight="regular">Ответственный в AMO</Text>
                              </SimpleCell>

                              <SimpleCell after={<Select type="text" onChange={this.handleAmoPipeline}>
                                  <option value="">выберите из списка</option>
                                  {
                                      this.state.amo_data.pipelines.map((element) => {

                                          return <option value={element.id}>{element.name}</option>;
                                      })}
                              </Select>}><Text weight="regular">Воронка в AMO</Text>
                              </SimpleCell>

                              <SimpleCell after={<Select type="text" onChange={this.handleAmoStatus}>
                                  <option value="">выберите из списка</option>
                                  {
                                      this.state.amo_data.statuses.map((element) => {
                                          if (element.pipeline_id == this.state.amo_pipeline_id) {

                                              return <option value={element.id}>{element.name}</option>;
                                          }
                                      })}
                              </Select>}><Text weight="regular">Статус в AMO</Text>
                              </SimpleCell>
                          </Group>
                          <Group mode="plain">
                              <SimpleCell after={<Switch onChange={this.handleActive}/>}><Text
                                weight="regular">Активно</Text>
                              </SimpleCell>
                          </Group>
                          <Group mode="plain">
                              <SimpleCell
                                after={<Switch checked={this.state.custom_btn}
                                               onChange={() => this.setState({custom_btn: !this.state.custom_btn})}/>}
                              >
                                  <Text weight="regular">Использовать кастомную кнопку</Text>
                              </SimpleCell>
                              {this.state.custom_btn ?
                                <>
                                    <SimpleCell after={<Input type='text' value={this.state.custom_btn_name}
                                                              onChange={(e) => this.setState({custom_btn_name: e.target.value})}/>}>
                                        <Text weight="regular">Имя кнопки</Text>
                                    </SimpleCell>
                                    <SimpleCell after={<Input type='text' value={this.state.custom_btn_link}
                                                              onChange={(e) => this.setState({custom_btn_link: e.target.value})}/>}>
                                        <Text weight="regular">Ссылка</Text>
                                    </SimpleCell>
                                </>

                                : null}
                          </Group>
                      </TabPanel>
                      <Div>
                          {this.state.isError ? <FormError error={[this.state.errorText]}/> : null}
                      </Div>
                      <Div style={{display: 'flex'}}>
                          <Button size="xl">Создать</Button>
                      </Div>
                  </FormLayout>
                }

            </FormLayoutGroup>
        </Panel>
    }

}
